<template>
    <div>
        <LoadingSpinner v-if="loading" class="vh-50" />
        <LoadingError v-else-if="error" class="vh-50" />
        <div v-show="!loading && !error" class="card">
            <div class="card-header">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Create Payment</h5>
            </div>
            <div class="card-body">
                <form @submit.prevent="createPayment()">
                    <fieldset :disabled="submitting">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="customer_select">Customer</label>
                                <CustomerSelect v-model="payment.customer_id"
                                                :customers="customers"
                                                :allow-empty="false"
                                                id="customer_select" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="amount">Amount</label>
                                <input type="number"
                                       min="0.01"
                                       max="999999.99"
                                       step="0.01"
                                       v-model="payment.amount"
                                       placeholder="0.00"
                                       id="amount"
                                       class="form-control"
                                       required>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="sales_order_number">
                                    Sales Order Number <small class="text-400">(Optional)</small>
                                </label>
                                <input type="number"
                                       v-model="payment.sales_order_number"
                                       id="sales_order_number"
                                       class="form-control">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="invoice_number">
                                    Invoice Number <small class="text-400">(Optional)</small>
                                </label>
                                <input type="number"
                                       v-model="payment.invoice_number"
                                       id="invoice_number"
                                       class="form-control">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="notes">Notes <small class="text-400">(Optional)</small></label>
                                <input v-model="payment.notes"
                                       maxlength="25"
                                       id="notes"
                                       class="form-control">
                            </div>
                        </div>
                        <transition v-if="$auth.hasSomePermission('read:bank_accounts', 'read:credit_cards')"
                                    name="fade">
                            <div v-if="payment.customer_id">
                                <div class="form-group d-inline-flex flex-column">
                                    <label>Payment Method</label>
                                    <div class="position-relative">
                                        <div v-if="$auth.hasPermissions('read:credit_cards')"
                                             class="form-check form-check-inline">
                                            <input v-model="paymentType"
                                                   id="paymentMethodCardInput"
                                                   name="payment_type"
                                                   class="form-check-input"
                                                   type="radio"
                                                   value="card">
                                            <label for="paymentMethodCardInput" class="form-check-label">
                                                Credit Card
                                            </label>
                                        </div>
                                        <div v-if="$auth.hasPermissions('read:bank_accounts')"
                                             class="form-check form-check-inline">
                                            <input v-model="paymentType"
                                                   id="paymentMethodBankInput"
                                                   name="payment_type"
                                                   class="form-check-input"
                                                   type="radio"
                                                   value="bank">
                                            <label for="paymentMethodBankInput" class="form-check-label">
                                                Bank Account
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <transition appear name="fade" mode="out-in">
                                    <div v-if="paymentType === 'card'" :key="'card'">
                                        <CustomerCreditCards :key="selectedCustomer && selectedCustomer.id"
                                                             :customer-id="selectedCustomer.id"
                                                             :customer-prefill-name="selectedCustomer.name"
                                                             :customer-prefill-email="selectedCustomer.email"
                                                             :processing="submitting"
                                                             :selected-option.sync="paymentMethodId"
                                                             @loadingSuccess="braintreeIsLoaded = true"
                                                             @loadingError="braintreeError"
                                                             @submissionError="braintreeError" />
                                    </div>
                                    <div v-if="paymentType === 'bank'" :key="'bank'">
                                        <CustomerBankAccounts :key="selectedCustomer && selectedCustomer.id"
                                                              :customer-id="selectedCustomer.id"
                                                              :processing="submitting"
                                                              :selected-option.sync="paymentMethodId"
                                                              @loadingSuccess="braintreeIsLoaded = true"
                                                              @loadingError="braintreeError"
                                                              @submissionError="braintreeError" />
                                    </div>
                                </transition>
                            </div>
                        </transition>
                        <button :disabled="!braintreeIsLoaded || !paymentMethodId"
                                type="submit"
                                class="btn btn-sm btn-falcon-primary mt-3 float-md-right">
                            <span v-if="submitting"
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true" />
                            Create Payment
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { createManualPayment } from '@/services/ManualPaymentService';
    import CustomerBankAccounts from '@/components/CustomerBankAccounts';
    import CustomerCreditCards from '@/components/CustomerCreditCards';
    import CustomerSelect from '@/components/CreatePayment/CustomerSelect.vue';
    import { getCustomerList } from '@/services/CustomerService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';

    export default {
        name: 'CreatePayment',
        components: {
            CustomerBankAccounts,
            LoadingSpinner,
            LoadingError,
            CustomerCreditCards,
            CustomerSelect,
        },
        data() {
            return {
                loading: true,
                error: null,
                submitting: false,
                payment: {
                    customer_id: null,
                    sales_order_number: '',
                    invoice_number: '',
                    amount: '',
                    notes: '',
                },
                braintreeIsLoaded: false,
                paymentMethodId: null,
                customers: [],
                paymentType: this.getDefaultPaymentType(),
            };
        },
        computed: {
            selectedCustomer() {
                if (this.payment.customer_id) {
                    return this.customers.find(c => c.id === this.payment.customer_id);
                }
                return null;
            },
        },
        watch: {
            selectedCustomer() {
                // Payment methods are scoped to a customer, so clear selection when selected customer changes
                this.paymentMethodId = null;
            },
            paymentMethod() {
                // If we switch between payment methods after having previously selected one, clear selection
                this.paymentMethodId = null;
            },
        },
        async mounted() {
            this.loading = true;
            try {
                const { data: customers } = await getCustomerList();
                this.customers = customers;
            } catch (error) {
                this.error = 'Failed to load payment component';
                this.$alerts.danger('An unexpected error occurred',
                                    'Please contact support if this problem persists.');
            } finally {
                this.loading = false;
            }
        },
        methods: {
            /**
             * Gets the default selected payment method based on the user's permissions.
             * Defaults to 'card' if the user has card permissions, 'bank' if they have
             * bank but not card permissions, and null otherwise.
             */
            getDefaultPaymentType() {
                if (this.$auth.hasPermissions('read:credit_cards')) {
                    return 'card';
                }
                if (this.$auth.hasPermissions('read:bank_accounts')) {
                    return 'bank';
                }

                return null;
            },
            async createPayment() {
                this.submitting = true;

                try {
                    if (this.payment.customer_id === null || !this.braintreeIsLoaded || !this.paymentMethodId) {
                        this.$alerts.danger('Failed to create payment',
                                            'Please check that all form information is complete.');
                        return;
                    }

                    await createManualPayment({
                        ...this.payment,
                        payment_type: this.paymentType,
                        payment_method_id: this.paymentMethodId,
                    });

                    this.$router.push({ name: 'payments' });
                } catch (error) {
                    this.$alerts.danger('Failed to create payment',
                                        'Please verify inputted data and try again.');
                } finally {
                    this.submitting = false;
                }
            },
            /**
             * Alert a user when Braintree has failed.
             * @param {string} message Explains the error to the user
             */
            braintreeError(message) {
                this.$alerts.danger(message, 'Please contact support if this problem persists.');
            },
        },
    };
</script>

<style scoped>
    .fade-enter-to, .fade-leave {
        transition: all 0.3s;
        overflow: hidden;
    }

    .fade-enter, .fade-leave-to {
        overflow: hidden;
        opacity: 0;
    }
</style>
